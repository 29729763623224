.nav-open {
	header {
		background: #444;
		z-index: 10;

		@media #{$mid-point} {
			background: none;
		}
	}

	nav {
		margin: 20px 0 0 0;
		border: 0;

		a {
			display: block;
		}

		@media #{$mid-point} {
			border: 0;
			padding: 0 20px;

			a {
				display: inline;
			}
		}
	}
}

nav {
	text-transform: uppercase;
	font-size: 0.8em;
	width: 100%;

	ul {
		margin: 0;
		padding: 0;
	}

	li {
		display: block;
	}

	@media #{$mid-point} {
		text-align: right;
		position: absolute;
		top: 0;
		right: 0;
		padding: 0 20px;

		li {
			display: inline-block;
		}
	}

	a {
		margin: 0 3px;
		padding: 20px 10px;
		color: rgba(255, 255, 255, 0.8);
		transition: 0.2s ease-in-out;
		display: none;
		font-size: 1rem;

		@media #{$mid-point} {
			border-bottom: 2px solid rgba(255, 255, 255, 0);
			display: block;
			padding: 10px;
		}

		&.nav-toggle {
			display: inline;
			position: absolute;
			right: 10px;
			top: -22px;
			font-size: 1.9em;
			border: 0;

			@media #{$mid-point} {
				display: none;
			}

			&:hover {
				border: 0;
			}
		}
	}

	a:hover {
		color: #fff;

		@media #{$mid-point} {
			border-bottom: 2px solid rgba(255, 255, 255, 0.6);
			display: block;
			padding: 10px;
		}
	}

	@media #{$mid-point} {
		a.highlight {
			border: 1px #ccc solid;
			border-radius: 5px;

			&:hover {
				background: #fff;
				color: $brand-color;
			}
		}
	}

	a.active {
		color: #fff;
	}
}
